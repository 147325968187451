import React from 'react';
import { useShoppingCart } from 'use-shopping-cart';

const Cart = () => {
  const {
    cartDetails,
    formattedTotalPrice,
    redirectToCheckout,
    incrementItem,
    decrementItem,
    removeItem,
  } = useShoppingCart();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('/.netlify/functions/create-checkout', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cartDetails),
    })
      .then((res) => res.json())
      .catch((error) => console.log(error));

    redirectToCheckout({ sessionId: response.sessionId });
  };

  return (
    <section className="cart">
      <header className="table-headers-container">
        <p className="tb-header first">Article</p>
        <p className="tb-header">Quantity</p>
        <p className="tb-header">Price</p>
        <p className="tb-header">Actions</p>
      </header>
      {Object.values(cartDetails).map((product) => (
        <div key={product.sku} className="table-rows-container">
          <img src={product.image} className="cart-img" alt={product.name} />
          <p className="cart-paragraph">{product.name}</p>
          <div className="quantity-container">
            <button
              aria-label="delete item"
              type="button"
              className="btn-quantity-decrease"
              onClick={() => decrementItem(product.sku)}
            >
              <span>
                -
              </span>
            </button>
            <p className="cart-paragraph">{product.quantity}</p>
            <button
              aria-label="add item"
              type="button"
              className="btn-quantity-increase"
              onClick={() => incrementItem(product.sku)}
            >
              <span>
                +
              </span>
            </button>
          </div>
          <p className="cart-paragraph">{product.formattedValue}</p>
          <button
            aria-label="remove item"
            type="button"
            className="btn-delete"
            onClick={() => removeItem(product.sku)}
          >
            Delete
          </button>
        </div>
      ))}
      <div className="cart-summary-container">
        <p className="price-total-title">Total Price</p>
        <p className="price-total-value">{formattedTotalPrice}</p>
        <button
          aria-label="go to checkout"
          type="button"
          className="btn btn-primary btn-checkout"
          onClick={handleSubmit}
        >
          Check Out
        </button>
      </div>
    </section>
  );
};

export default Cart;
