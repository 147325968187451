import React from 'react';
import Layout from '../layouts/main/Layout';
import Cart from '../components/Cart/Cart';
import NoIndex from '../util/NoIndex/NoIndex';

const cart = ({ ...props }) => {
  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <NoIndex />
      <Cart />
    </Layout>
  );
};

export default cart;
